import React, { useLayoutEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import {
  Block,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  Button,
  Row,
  Col,
  PreviewCard,
  ReactDataTable,
} from "../../../components/Components";
import Head from "../../../layout/head/Head";
import { Badge, Card } from "reactstrap";
import {
  useGetPartnerSubscriptionsQuery,
  useGetPlansQuery,
} from "../../../redux/api/subscription/subscriptionApi";
import { useSelector, useDispatch } from "react-redux";
import {
  onChangePageState,
  onFormCancel,
  toggleModal,
} from "../../../redux/features/admin-portal/subscriptions/subscriptionOverviewSlice";
import { capitalize } from "../../../utils/utils";
import { subscriptionOverviewColumns } from "./DataColumns";

const SubscriptionOverview = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.subscriptionOverview);
  const { data, isLoading } = useGetPlansQuery({
    offset: state.offset,
    itemsPerPage: state.itemsPerPage,
  });
  const { data: partnerSubscriptions } = useGetPartnerSubscriptionsQuery({
    offset: state.offset,
    itemsPerPage: state.itemsPerPage,
    is_overview: true,
  });
  const [tableData, setTableData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  useLayoutEffect(() => {
    if (partnerSubscriptions) {
      console.log("asdasd", partnerSubscriptions);

      setTableData(partnerSubscriptions.subscriptions);
      setTotalItems(partnerSubscriptions.total);
    }
  }, [partnerSubscriptions]);

  console.log(data);

  const changePageState = ({ offset = 0, itemsPerPage }) => {
    dispatch(onChangePageState({ offset, itemsPerPage: itemsPerPage }));
  };

  return (
    <React.Fragment>
      <Head title="Subscription Overview"></Head>
      <Content>
        <Block>
          <BlockHead>
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>Subscription Overview</BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
        </Block>

        <Block size="lg">
          <Row className="g-gs">
            {data?.length === 0 ? (
              <div className="text-center">No data found</div>
            ) : (
              data?.map((item) => {
                return (
                  <Col md={4} xxl={3} key={item.id}>
                    <Card
                      className={`pricing text-center ${
                        item.tags ? "recommend" : ""
                      }`}
                    >
                      <div className="pricing-body">
                        <div className="pricing-media">
                          <img src={item.logo} alt="" />
                        </div>
                        <div className="pricing-title w-220px mx-auto">
                          <h5 className="title">{capitalize(item.name)}</h5>
                          <span className="sub-text">{item.description}</span>
                        </div>
                        <div className="pricing-amount">
                          <div className="amount">
                            ${item.price} <span>/license</span>
                          </div>
                        </div>
                        <div className="pricing-action">
                          <Button color="primary">Edit</Button>
                        </div>
                      </div>
                    </Card>
                  </Col>
                );
              })
            )}
          </Row>
        </Block>

        <Block>
          <PreviewCard>
            <div className="table-wrapper is-scrollable">
              <ReactDataTable
                data={tableData}
                columns={subscriptionOverviewColumns}
                offset={state.offset}
                itemsPerPage={state.itemsPerPage}
                pagination
                totalItems={totalItems}
                className="nk-tb-list"
                selectableRows
                changePageState={changePageState}
              />
            </div>
          </PreviewCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default SubscriptionOverview;

export const pricingTableDataV2 = [
  {
    id: 1,
    title: "Starter",
    desc: "Starter plan description",
    amount: 20,
  },
  {
    id: 2,
    title: "Pro",
    desc: "Pro plan description",
    amount: 40,
  },
  {
    id: 3,
    title: "Enterprise",
    desc: "Enterprise plan description",
    amount: 60,
  },
];
