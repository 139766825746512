import { useDispatch, useSelector } from "react-redux";
import { useState, useLayoutEffect, useEffect } from "react";
import {
  Button,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
  ReactDataTable,
  ModalComponent,
  SelectField,
  Row,
  Col,
} from "../../../components/Components";
import { accountSubcriptionsColumns } from "./DataColumns";
import {
  onChangePageState,
  onFormCancel,
  toggleModal,
} from "../../../redux/features/app-portal/subcriptions/accountSubcriptionSlice";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { useForm } from "react-hook-form";
import { useLazyGetOrganisationQuery } from "../../../redux/api/organisations/organisationApi";
import { useLazyGetAppUsersQuery } from "../../../redux/api/users/usersApi";
import { AsyncSelectField } from "../../../components/form/SelectField";
import { Spinner } from "reactstrap";
import {
  useCreatePartnerAccountsMutation,
  useDeletePartnerAccountMutation,
  useUpdatePartnerAccountMutation,
} from "../../../redux/api/partners/partnerAccountApi";
import {
  useGetPartnerAccountSubscriptionsQuery,
  useLazyGetPartnerSubscriptionsQuery,
  useCreatePartnerAccountSubscriptionMutation,
  useUpdatePartnerAccountSubscriptionMutation,
  useDeletePartnerAccountSubscriptionMutation,
} from "../../../redux/api/subscription/subscriptionApi";
import { toast } from "react-toastify";

const AccountSubcriptions = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.accountSubcription);
  const { data: partnerAccountsData, isLoading: isGetPartnerAccountsLoading } =
    useGetPartnerAccountSubscriptionsQuery({
      offset: state.offset,
      limit: state.itemsPerPage,
      total: true,
      reverse: true,
    });
  const defaultValues = {
    subscription: null,
    user_email: null,
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    control,
    reset,
    setValue,
  } = useForm({
    defaultValues,
  });
  const [
    getPartnerSubscriptions,
    {
      data: partnerSubscriptionsData,
      isLoading: isGetPartnerSubscriptionsLoading,
      isError: isGetPartnerSubscriptionsError,
    },
  ] = useLazyGetPartnerSubscriptionsQuery();
  const [
    getAppUsers,
    {
      data: userData,
      isLoading: isGetAppUsersFetching,
      isError: isGetAppUsersError,
    },
  ] = useLazyGetAppUsersQuery();

  const [
    createPartnerAccountSubscription,
    {
      isLoading: isCreateLoading,
      isSuccess: isCreateSuccess,
      isError: isCreateError,
      error: createError,
    },
  ] = useCreatePartnerAccountSubscriptionMutation();

  const [
    updatePartnerAccountSubscription,
    {
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
      error: updateError,
    },
  ] = useUpdatePartnerAccountSubscriptionMutation();

  const [
    deletePartnerAccountSubscription,
    {
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
      isError: isDeleteError,
      error: deleteError,
    },
  ] = useDeletePartnerAccountSubscriptionMutation();

  const [tableData, setTableData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [defaultUserOptions, setDefaultUserOptions] = useState([]);

  const changePageState = ({ offset = 0, itemsPerPage }) => {
    dispatch(onChangePageState({ offset, itemsPerPage: itemsPerPage }));
  };

  const processData = (data) => {
    data.subscription_id = data.subscription.value;
    data.user_email = data.email;
    console.log(data);
  };

  const onFormSubmit = (data) => {
    processData(data);
    createPartnerAccountSubscription(data);
  };

  useLayoutEffect(() => {
    if (partnerAccountsData) {
      setTableData(partnerAccountsData.subscriptions);
      setTotalItems(partnerAccountsData.total);
    }
  }, [partnerAccountsData]);

  useEffect(() => {
    if (isCreateSuccess) {
      dispatch(toggleModal({ type: "add" }));
      reset(defaultValues);
      toast.success("Subscription created successfully");
    }
    if (isCreateError && createError) {
      toast.error(createError?.data?.message);
    }
  }, [isCreateSuccess, isCreateError]);

  const onAddBtnClicked = () => {
    getPartnerSubscriptions(
      {
        in_use: false,
      },
      false
    );
    dispatch(toggleModal({ type: "add" }));
  };

  const onRowClicked = (row, e) => {
    e.stopPropagation();
    const action = e.target.getAttribute("data-action");
    switch (action) {
      case "edit":
        break;
      case "remove":
        deletePartnerAccountSubscription({ id: row.id });
        break;
      default:
    }
  };

  const loadUsersOptions = async (inputValue, callback) => {
    if (inputValue.length < 3) return;
    let response = await getAppUsers(
      { offset: 0, limit: 0, search_text: inputValue, is_pure: true },
      true
    ).unwrap();

    if (response.users) {
      const options = response.users.map((item) => {
        let label = "";
        if (item.user.first_name) {
          label += item.user.first_name;
        }
        if (item.user.last_name) {
          label += " " + item.user.last_name;
        }
        label += " - " + item.user.email;

        return { label: label, value: item.user.email };
      });
      callback(options);
    }
  };

  const noOptionsMessage = (data) => {
    if (data.inputValue.length >= 3) {
      return "No results found";
    }
    return "Minimum 3 characters required";
  };

  return (
    <>
      <Head title="Account Subcriptions"></Head>
      <Content>
        <Block>
          <BlockHead>
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>Account Subcriptions</BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent>
                <div className="toggle-wrap nk-block-tools-toggle">
                  <Button
                    className="toggle d-none d-md-inline-flex mr-3"
                    color="primary"
                    onClick={() => {
                      onAddBtnClicked();
                    }}
                  >
                    <Icon name="plus"></Icon>
                    <span>Create Subscription</span>
                  </Button>
                </div>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <PreviewCard>
            <ReactDataTable
              data={tableData}
              columns={accountSubcriptionsColumns}
              offset={state.offset}
              itemsPerPage={state.itemsPerPage}
              pagination
              totalItems={totalItems}
              // progressPending={isFetching}
              className="nk-tb-list"
              selectableRows
              changePageState={changePageState}
              onRowClicked={onRowClicked}
            />
          </PreviewCard>
        </Block>
      </Content>

      <ModalComponent
        isOpen={state.modal.add || state.modal.edit}
        title={`${state.modal.add ? "Add" : "Edit"} Partner Subscription`}
        toggle={() => {
          dispatch(onFormCancel());
          reset(defaultValues);
        }}
        backdrop={"static"}
        onModalCancel={() => {
          dispatch(onFormCancel());
          reset(defaultValues);
        }}
      >
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Row className="g-3">
            <Col size="6">
              <label className="form-label">User</label>
              <span className="text-danger"> *</span>
              <Col size="0">
                <AsyncSelectField
                  defaultValue={
                    state.modal.add
                      ? null
                      : {
                          label: `${state.modal.item?.first_name} ${state.modal.item?.last_name} - ${state.modal.item?.email}`,
                          value: state.modal.item?.email,
                        }
                  }
                  defaultOptions={defaultUserOptions}
                  onChangeFunc={(newValue) => {
                    setValue("email", newValue.value);
                  }}
                  loadOptions={async (inputValue, callback) => {
                    await loadUsersOptions(inputValue, callback);
                  }}
                  noOptionsMessage={(data) => noOptionsMessage(data)}
                  cacheOptions
                />
              </Col>
            </Col>
            <SelectField
              name={"subscription"}
              label={"Subscription"}
              control={control}
              rules={{ required: "This field is required" }}
              isSearchable={false}
              placeholder={
                partnerSubscriptionsData?.subscriptions.length == 0
                  ? "No subscriptions found"
                  : "Select Subscription"
              }
              options={
                partnerSubscriptionsData?.subscriptions.map((item) => ({
                  label: `${item.plan} - ${item.id}`,
                  value: item.id,
                })) ?? {}
              }
              isLoading={isGetPartnerSubscriptionsLoading}
              isDisabled={
                isGetPartnerSubscriptionsLoading ||
                partnerSubscriptionsData?.subscriptions.length == 0
              }
              errors={errors}
            />

            <Col size="12">
              <Button
                color="primary"
                type="submit"
                disabled={isCreateLoading || isUpdateLoading}
              >
                {(isCreateLoading || isUpdateLoading) && (
                  <Spinner type="grow" size="sm" />
                )}
                <span>{state.modal.add ? "Add" : "Edit"}</span>
              </Button>
            </Col>
          </Row>
        </form>
      </ModalComponent>
    </>
  );
};

export default AccountSubcriptions;
