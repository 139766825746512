import { useDispatch, useSelector } from "react-redux";
import { useState, useLayoutEffect, useEffect } from "react";
import ReactDataTable from "../../../components/table/ReactDataTable";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  Button,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
  Row,
  Col,
  DatePickerInput,
  ModalComponent,
  SelectField,
  TextInput,
} from "../../../components/Components";
import {
  onChangePageState,
  toggleModal,
  onFormCancel,
} from "../../../redux/features/admin-portal/subscriptions/partnerSubscriptionSlice";
import { organisationColumns } from "./DataColumns";
import {
  Breadcrumb,
  BreadcrumbItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useForm } from "react-hook-form";
import {
  useUpdatePartnerSubscriptionMutation,
  useCreatePartnerSubscriptionMutation,
  useGetPartnerSubscriptionsQuery,
  useLazyGetPlansQuery,
  useDeletePartnerSubscriptionMutation,
} from "../../../redux/api/subscription/subscriptionApi";
import { capitalize } from "../../../utils/utils";
import { useLazyGetOrganisationsQuery } from "../../../redux/api/organisations/organisationApi";
import { toast } from "react-toastify";
import { partnerSubscriptionColumns } from "./DataColumns";
import moment from "moment";

const paymentTypeOptions = [
  { value: "monthly", label: "Monthly" },
  { value: "one_time", label: "One-time" },
];

const PartnerSubscriptions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state) => state.partnerSubscription);
  const { data, isFetching, isSuccess } = useGetPartnerSubscriptionsQuery({
    offset: state.offset,
    limit: state.itemsPerPage,
    total: true,
    reverse: true,
  });
  const [getPlans, { data: plans, isFetching: isPlansFetching }] =
    useLazyGetPlansQuery();

  const [
    getOrganisations,
    { data: organisations, isFetching: isOrganisationsFetching },
  ] = useLazyGetOrganisationsQuery();
  const [
    createPartnerSubscription,
    {
      isLoading: isCreateLoading,
      isSuccess: isCreateSuccess,
      isError: isCreateError,
      error: createError,
    },
  ] = useCreatePartnerSubscriptionMutation();
  const [
    updatePartnerSubscription,
    {
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
      error: updateError,
    },
  ] = useUpdatePartnerSubscriptionMutation();
  const [
    deletePartnerSubscription,
    {
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
      isError: isDeleteError,
      error: deleteError,
    },
  ] = useDeletePartnerSubscriptionMutation();
  const defaultValues = {
    payment_type: paymentTypeOptions[0],
    valid_from: null,
    valid_to: null,
    paid_on: null,
    payment_details: "",
    payment_amount: 0,
    payment_type: paymentTypeOptions[0],
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    control,
    reset,
  } = useForm({
    defaultValues,
  });
  const [tableData, setTableData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  useLayoutEffect(() => {
    if (data) {
      setTableData(data.subscriptions);
      setTotalItems(data.total);
    }
  }, [data]);

  useEffect(() => {
    if (isCreateSuccess) {
      dispatch(toggleModal({ type: "add" }));
      reset(defaultValues);
      toast.success("Subscription created successfully");
    }
    if (isCreateError && createError) {
      toast.error(createError?.data?.message);
    }
  }, [isCreateSuccess, isCreateError]);

  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(toggleModal({ type: "edit" }));
      reset(defaultValues);
      toast.success("Subscription updated successfully");
    }
    if (isUpdateError && updateError) {
      toast.error(updateError?.data?.message);
    }
  }, [isUpdateSuccess, isUpdateError]);

  const changePageState = ({ offset = 0, itemsPerPage }) => {
    dispatch(onChangePageState({ offset, itemsPerPage: itemsPerPage }));
  };

  const onAddBtnClicked = () => {
    getPlans(undefined, true);
    getOrganisations(undefined, true);
    dispatch(toggleModal({ type: "add" }));
  };

  const processData = (data) => {
    data.organisation = data.organisation?.value;
    data.plan = data.plan?.label;
    data.payment_type = data.payment_type?.value;
    data.payment_amount = parseFloat(data.payment_amount);
    data.valid_from = data.valid_from
      ? new Date(data.valid_from).toISOString()
      : null;
    data.valid_to = data.valid_to
      ? new Date(data.valid_to).toISOString()
      : null;
    data.paid_on = data.paid_on ? new Date(data.paid_on).toISOString() : null;
    return data;
  };

  const onFormSubmit = (data) => {
    const processedData = processData(data);
    console.log(processedData);

    if (state.modal.add) {
      createPartnerSubscription(processedData);
    } else {
      updatePartnerSubscription({
        params: { id: state.modal.item.id },
        data: processedData,
      });
    }
  };

  const onRowClicked = (row, e) => {
    e.stopPropagation();
    const action = e.target.getAttribute("data-action");
    switch (action) {
      case "details":
        navigate(`../${row.id}`);
        break;
      case "edit":
        getPlans(undefined, true);
        getOrganisations(undefined, true);
        dispatch(toggleModal({ type: "edit", item: row }));
        reset(
          {
            ...row,
            organisation: {
              value: row.organisation,
              label: row.organisation.name,
            },
            plan: {
              value: plans?.find((plan) => capitalize(plan.name) === row.plan)
                ?.id,
              label: row.plan,
            },
            payment_type: {
              value: row.payment_type,
              label: paymentTypeOptions.find(
                (option) => option.value === row?.payment_type
              )?.label,
            },
            valid_from: moment(row.valid_from).toDate(),
            valid_to: moment(row.valid_to).toDate(),
            paid_on: moment(row.paid_on).toDate(),
          },
          {
            keepDefaultValues: true,
          }
        );
        break;
      case "remove":
        //dispatch delete
        deletePartnerSubscription({ id: row.id });
        break;
      default:
    }
  };

  return (
    <>
      <Head title="Partner Subscriptions"></Head>
      <Content>
        <Block>
          <BlockHead>
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>Partner Subscriptions</BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent>
                <div className="toggle-wrap nk-block-tools-toggle">
                  <Button
                    className="toggle d-none d-md-inline-flex mr-3"
                    color="primary"
                    onClick={() => {
                      onAddBtnClicked();
                    }}
                  >
                    <Icon name="plus"></Icon>
                    <span>Create Subscription</span>
                  </Button>
                </div>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <PreviewCard>
            <div className="table-wrapper is-scrollable">
              <ReactDataTable
                data={tableData}
                columns={partnerSubscriptionColumns}
                offset={state.offset}
                itemsPerPage={state.itemsPerPage}
                pagination
                totalItems={totalItems}
                className="nk-tb-list"
                selectableRows
                changePageState={changePageState}
                onRowClicked={onRowClicked}
              />
            </div>
          </PreviewCard>
        </Block>
      </Content>

      <ModalComponent
        isOpen={state.modal.add || state.modal.edit}
        title={`${state.modal.add ? "Add" : "Edit"} Partner Subscription`}
        toggle={() => {
          dispatch(onFormCancel());
          reset(defaultValues);
        }}
        backdrop={"static"}
        onModalCancel={() => {
          dispatch(onFormCancel());
          reset(defaultValues);
        }}
      >
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Row className="g-3">
            <SelectField
              name={"organisation"}
              label={"Organisation"}
              control={control}
              rules={{ required: "This field is required" }}
              isLoading={isOrganisationsFetching}
              isDisabled={!organisations || isOrganisationsFetching}
              options={organisations?.organisations.map((org) => ({
                value: org,
                label: org.name,
              }))}
              errors={errors}
            />
            <SelectField
              name={"plan"}
              label={"Plan"}
              control={control}
              rules={{ required: "This field is required" }}
              isLoading={isPlansFetching}
              isDisabled={!plans || isPlansFetching}
              options={plans?.map((plan) => ({
                value: plan.id,
                label: capitalize(plan.name),
              }))}
              errors={errors}
            />
            <DatePickerInput
              name={"valid_from"}
              label={"Valid from"}
              placeholder={"dd/mm/yyyy"}
              dateFormat={"dd/MM/yyyy"}
              control={control}
              errors={errors}
              md="12"
            />
            <DatePickerInput
              name={"valid_to"}
              label={"Valid to"}
              placeholder={"dd/mm/yyyy"}
              dateFormat={"dd/MM/yyyy"}
              control={control}
              errors={errors}
              md="12"
            />
            <TextInput
              name={"payment_amount"}
              label={"Payment amount"}
              register={{
                ...register("payment_amount"),
              }}
              errors={errors}
              md="6"
            />
            <SelectField
              name={"payment_type"}
              label={"Payment type"}
              control={control}
              options={paymentTypeOptions}
              defaultValue={paymentTypeOptions[0]}
              errors={errors}
              md="6"
            />
            <TextInput
              name={"payment_details"}
              label={"Payment details"}
              register={{
                ...register("payment_details"),
              }}
              errors={errors}
              md="12"
            />
            <DatePickerInput
              name={"paid_on"}
              label={"Paid on"}
              placeholder={"dd/mm/yyyy"}
              dateFormat={"dd/MM/yyyy"}
              control={control}
              errors={errors}
              md="12"
            />

            <Col size="12">
              <Button
                color="primary"
                type="submit"
                disabled={isCreateLoading || isUpdateLoading}
              >
                {(isCreateLoading || isUpdateLoading) && (
                  <Spinner type="grow" size="sm" />
                )}
                <span>{state.modal.add ? "Add" : "Edit"}</span>
              </Button>
            </Col>
          </Row>
        </form>
      </ModalComponent>
    </>
  );
};

export default PartnerSubscriptions;
