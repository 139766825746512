import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { Icon, TooltipComponent } from "../../../components/Components";
import moment from "moment";

export const subscriptionOverviewColumns = [
  {
    name: "Partner ID",
    selector: (row) => row.organisation.id,
    minWidth: "150px",
    sortable: true,
  },
  {
    name: "Partner Name",
    selector: (row) => row.organisation.name,
    minWidth: "150px",
    sortable: true,
  },
  {
    name: "Number of paid subscriptions",
    selector: (row) => row.paid_subscriptions,
    minWidth: "150px",
    sortable: true,
  },
  {
    name: "Number of active subscriptions",
    selector: (row) => row.active_subscriptions,
    minWidth: "150px",
    sortable: true,
  },
];

export const partnerSubscriptionColumns = [
  {
    name: "Partner ID",
    selector: (row) => row.organisation.id,
    minWidth: "300px",
    sortable: true,
    style: { paddingRight: "20px" },
    cell: (row) => <span>{row.organisation.id} - {row.organisation.name}</span>,
  },
  {
    name: "Subscription ID",
    minWidth: "150px",
    cell: (row) => <span>{row.id}</span>,
    hide: "md",
  },
  {
    name: "Plan",
    minWidth: "150px",
    cell: (row) => <span>{row.plan}</span>,
    hide: "md",
  },
  {
    name: "Valid From",
    selector: (row) => row.valid_from,
    sortable: true,
    minWidth: "200px",
    cell: (row) => (
      <span>{moment(row.valid_from).format("DD-MM-YYYY")}</span>
    ),
    hide: "md",
  },
  {
    name: "Valid To",
    minWidth: "150px",
    cell: (row) => (
      <span>{moment(row.valid_to).format("DD-MM-YYYY")}</span>
    ),
    hide: "md",
  },
  {
    name: "Payment Amount",
    minWidth: "150px",
    cell: (row) => <span>{row.payment_amount}</span>,
    hide: "md",
  },

  {
    name: "Payment Type",
    minWidth: "150px",
    cell: (row) => <span>{row.payment_type}</span>,
    hide: "md",
  },
  {
    name: "Payment Details",
    minWidth: "150px",
    cell: (row) => <span>{row.payment_details}</span>,
    hide: "md",
  },
  {
    name: "Paid On",
    selector: (row) => row.paid_on,
    sortable: true,
    minWidth: "150px",
    cell: (row) => (
      <span>{moment(row.paid_on).format("DD-MM-YYYY")}</span>
    ),
    hide: "md",
  },
  {
    name: "In Use",
    selector: (row) => row.in_use,
    sortable: true,
    minWidth: "120px",
    cell: (row) => <span>{row.in_use ? "Yes" : "No"}</span>,
    hide: "md",
  },
  {
    name: "Created",
    selector: (row) => row.created_time,
    sortable: true,
    minWidth: "120px",
    cell: (row) => (
      <span>{moment(row.created_time).format("DD-MM-YYYY, hh:mm:ss a")}</span>
    ),
    hide: "md",
  },
  {
    name: "Updated",
    selector: (row) => row.last_updated_time,
    sortable: true,
    minWidth: "120px",
    cell: (row) => (
      <span>
        {moment(row.last_updated_time).format("DD-MM-YYYY, hh:mm:ss a")}
      </span>
    ),
    hide: "md",
  },
  {
    button: true,
    cell: (row) => (
      <div className="nk-tb-col nk-tb-col-tools" data-tag="allowRowEvents">
        <ul className="nk-tb-actions gx-1">
          <li className="nk-tb-action-hidden">
            <TooltipComponent
              tag="a"
              containerClassName="btn btn-trigger btn-icon"
              id={"view" + row.id}
              icon="eye"
              direction="top"
              text="View Details"
              data-tag="allowRowEvents"
              data-action="details"
            />
          </li>
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#markasdone"
                      data-tag="allowRowEvents"
                      data-action="details"
                    >
                      <Icon
                        name="eye"
                        data-tag="allowRowEvents"
                        data-action="details"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="details">
                        View Details
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#edit"
                      data-tag="allowRowEvents"
                      data-action="edit"
                    >
                      <Icon
                        name="edit"
                        data-tag="allowRowEvents"
                        data-action="edit"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="edit">
                        Edit
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#remove"
                      data-tag="allowRowEvents"
                      data-action="remove"
                    >
                      <Icon
                        name="trash"
                        data-tag="allowRowEvents"
                        data-action="remove"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="remove">
                        Remove
                      </span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </div>
    ),
  },
];

export const partnerAccountSubscriptionColumns = [
  {
    name: "Partner details",
    selector: (row) => row.organisation.id,
    cell: (row) => (
      <span>
        {row.organisation.id} - {row.organisation.name}
      </span>
    ),
    minWidth: "150px",
    sortable: true,
  },
  {
    name: "User details",
    selector: (row) => row.user.id,
    minWidth: "150px",
    sortable: true,
    cell: (row) => (
      <span>
        {row.user.id} - {row.user.name}
      </span>
    ),
  },
  {
    name: "Subscription details",
    cell: (row) => (
      <span>
        {row.subscription.id} - {row.subscription.plan}
      </span>
    ),
  },
  {
    name: "Created",
    selector: (row) => row.created_time,
    sortable: true,
    minWidth: "120px",
    cell: (row) => (
      <span>{moment(row.created_time).format("DD-MM-YYYY, hh:mm:ss a")}</span>
    ),
  },
  {
    name: "Updated",
    selector: (row) => row.last_updated_time,
    sortable: true,
    minWidth: "120px",
    cell: (row) => (
      <span>
        {moment(row.last_updated_time).format("DD-MM-YYYY, hh:mm:ss a")}
      </span>
    ),
  },
];
