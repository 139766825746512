import { api } from "../api";

export const subscriptionApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPlans: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/plans",
          params: params,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: ["Plans"],
    }),
    updatePlan: builder.mutation({
      query: ({ params, data }) => {
        return {
          url: "admin/v1/plans",
          method: "PUT",
          params: params,
          body: data,
        };
      },
      invalidatesTags: ["Plans"],
    }),
    getPartnerSubscriptions: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/partner-subscriptions",
          params: params,
        };
      },
      transformResponse: (response) => {
        return {
          subscriptions: response.data,
          total: response.total,
        };
      },
      providesTags: ["PartnerSubscriptions"],
    }),
    createPartnerSubscription: builder.mutation({
      query: (data) => ({
        url: "admin/v1/partner-subscriptions",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["PartnerSubscriptions"],
    }),
    updatePartnerSubscription: builder.mutation({
      query: ({ params, data }) => {
        return {
          url: "admin/v1/partner-subscriptions",
          method: "PUT",
          params: params,
          body: data,
        };
      },
      invalidatesTags: ["PartnerSubscriptions"],
    }),
    deletePartnerSubscription: builder.mutation({
      query: (params) => {
        return {
          url: "admin/v1/partner-subscriptions",
          method: "DELETE",
          params: params,
        };
      },
      invalidatesTags: ["PartnerSubscriptions"],
    }),
    getPartnerAccountSubscriptions: builder.query({
      query: (params) => {
        return {
          url: "admin/v1/account-subscriptions",
          params: params,
        };
      },
      transformResponse: (response) => {
        return {
          subscriptions: response.data,
          total: response.total,
        };
      },
      providesTags: ["PartnerAccountSubscriptions"],
    }),
    createPartnerAccountSubscription: builder.mutation({
      query: (data) => ({
        url: "admin/v1/account-subscriptions",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["PartnerAccountSubscriptions"],
    }),
    updatePartnerAccountSubscription: builder.mutation({
      query: ({ params, data }) => {
        return {
          url: "admin/v1/account-subscriptions",
          method: "PUT",
          params: params,
          body: data,
        };
      },
      invalidatesTags: ["PartnerAccountSubscriptions"],
    }),
    deletePartnerAccountSubscription: builder.mutation({
      query: (params) => {
        return {
          url: "admin/v1/account-subscriptions",
          method: "DELETE",
          params: params,
        };
      },
      invalidatesTags: ["PartnerAccountSubscriptions"],
    }),
  }),
});

export const {
  useGetPlansQuery,
  useLazyGetPlansQuery,
  useUpdatePlanMutation,
  useGetPartnerSubscriptionsQuery,
  useLazyGetPartnerSubscriptionsQuery,
  useCreatePartnerSubscriptionMutation,
  useUpdatePartnerSubscriptionMutation,
  useDeletePartnerSubscriptionMutation,
  useGetPartnerAccountSubscriptionsQuery,
  useCreatePartnerAccountSubscriptionMutation,
  useUpdatePartnerAccountSubscriptionMutation,
  useDeletePartnerAccountSubscriptionMutation,
} = subscriptionApi;
