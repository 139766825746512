export const menu = [
  {
    icon: "dashboard",
    text: "Dashboard",
    link: "/admin",
    active: false,
  },
  {
    icon: "dashboard",
    text: "Accounts",
    active: false,
    subMenu: [
      {
        text: "Partners",
        link: "/admin/partners",
      },
    ],
  },
  {
    icon: "dashboard",
    text: "Partners",
    active: false,
    subMenu: [
      {
        text: "Partner accounts",
        link: "/admin/partner-accounts",
      },
    ],
  },
  // {
  //   icon: "dashboard",
  //   text: "Forms",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Introduction",
  //       link: "/admin/introduction-forms",
  //     },
  //     {
  //       text: "Assessment",
  //       link: "/admin/assessment-forms",
  //     },
  //     {
  //       text: "Inclus Web Submissions",
  //       subMenu: [
  //         {
  //           text: "Careers",
  //           link: "/admin/web-submissions/careers",
  //         },
  //         {
  //           text: "Contact Us",
  //           link: "/admin/web-submissions/contact-us",
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    icon: "dashboard",
    text: "Subscriptions",
    active: false,
    subMenu: [
      {
        text: "Overview",
        link: "/admin/subscriptions/overview",
      },
      {
        text: "Partner subscriptions",
        link: "/admin/subscriptions/partners",
      },
      {
        text: "Partner accounts subscriptions",
        link: "/admin/subscriptions/partner-accounts",
      },
    ],
  },
  {
    icon: "dashboard",
    text: "Accounts Config",
    active: false,
    subMenu: [
      // {
      //   text: "Account types",
      //   link: "/admin/config/account-types",
      // },
      {
        text: "Account roles",
        link: "/admin/config/account-roles",
      },
      {
        text: "User access control",
        link: "/admin/config/uac",
      },
    ],
  },
  // {
  //   icon: "dashboard",
  //   text: "Authorization",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Permission",
  //       link: "/admin/authorization/permission",
  //     },
  //     {
  //       text: "Role",
  //       link: "/admin/authorization/role",
  //     },
  //   ],
  // },
  // {
  //   icon: "dashboard",
  //   text: "Config",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Form Email Sending",
  //       link: "/admin/config/form-email-sending",
  //     },
  //     {
  //       text: "Menu Data",
  //       link: "/admin/config/menu-data",
  //     },
  //   ],
  // },
  // {
  //   icon: "dashboard",
  //   text: "Dropdown Data",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Emotions",
  //       link: "/admin/dropdown-config/emotions",
  //     },
  //     {
  //       text: "Sex",
  //       link: "/admin/dropdown-config/sex",
  //     },
  //     {
  //       text: "Religions",
  //       link: "/admin/dropdown-config/religions",
  //     },
  //     {
  //       text: "Interaction Types",
  //       link: "/admin/dropdown-config/interaction-types",
  //     },
  //     {
  //       text: "Medical Types",
  //       link: "/admin/dropdown-config/medical-types",
  //     },
  //     {
  //       text: "Races",
  //       link: "/admin/dropdown-config/races",
  //     },
  //     {
  //       text: "Education Levels",
  //       link: "/admin/dropdown-config/education-levels",
  //     },
  //     {
  //       text: "Org Contact Roles",
  //       link: "/admin/dropdown-config/org-contact-roles",
  //     },
  //     {
  //       text: "Org Supporting Doc Types",
  //       link: "/admin/dropdown-config/org-supporting-doc-types",
  //     },
  //     {
  //       text: "Opportunity Types",
  //       link: "/admin/dropdown-config/opportunity-types",
  //     },
  //     {
  //       text: "Opportunity Contract Types",
  //       link: "/admin/dropdown-config/opportunity-contract-types",
  //     },
  //     {
  //       text: "Opportunity Commitment Types",
  //       link: "/admin/dropdown-config/opportunity-commitment-types",
  //     },
  //     {
  //       text: "Observation Grading",
  //       link: "/admin/dropdown-config/observation-grading",
  //     },
  //   ],
  // },
  // {
  //   icon: "dashboard",
  //   text: "Chatbot",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Conversations",
  //       link: "/admin/chatbot/conversations",
  //     },
  //     {
  //       text: "Components",
  //       link: "/admin/chatbot/components",
  //     },
  //     {
  //       text: "Try the bot",
  //       link: "/admin/chatbot/test",
  //     },
  //     {
  //       text: "Train",
  //       link: "/admin/chatbot/train",
  //     },
  //     // {
  //     //   text: "Intents",
  //     //   link: "/admin/chatbot/intents",
  //     // },
  //     // {
  //     //   text: "Flow Builder",
  //     //   link: "/admin/chatbot/flow-builder",
  //     // }
  //   ],
  // },
  // {
  //   heading: "Mobile Apps",
  // },
  // {
  //   icon: "users",
  //   text: "Account Management",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Account",
  //       link: "/admin/account",
  //     },
  //   ],
  // },
  // {
  //   icon: "home",
  //   text: "Home Management",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Quotes",
  //       link: "/admin/quotes",
  //     },
  //   ],
  // },
  // {
  //   icon: "task-c",
  //   text: "Goal Management",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Goals",
  //       link: "/admin/goals",
  //     },
  //     {
  //       text: "Goal Frequency",
  //       link: "/admin/goal-frequency",
  //     },
  //     {
  //       text: "Goal Relationship",
  //       link: "/admin/goal-relationship",
  //     },
  //     {
  //       text: "Goal Tracking",
  //       link: "/admin/goal-tracking",
  //     },
  //   ],
  // },
  // {
  //   icon: "help-alt",
  //   text: "Support Management",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Attendance",
  //       link: "/admin/attendance",
  //     },
  //     {
  //       text: "Support Metrics",
  //       subMenu: [
  //         {
  //           text: "Soft skills",
  //           link: "/admin/support-metrics/soft-skills",
  //         },
  //         {
  //           text: "Hard skills",
  //           link: "/admin/support-metrics/hard-skills",
  //         },
  //         {
  //           text: "Motor skills",
  //           link: "/admin/support-metrics/motor-skills",
  //         },
  //         {
  //           text: "Others",
  //           link: "/admin/support-metrics/others",
  //         },
  //       ],
  //     },
  //     {
  //       text: "Support Logs",
  //       link: "/admin/support-logs",
  //     },
  //     {
  //       text: "Location Tracking",
  //       link: "/admin/location-tracking",
  //     },
  //   ],
  // },
  // {
  //   icon: "happy",
  //   text: "Emotional Management",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Emotional Logs",
  //       link: "/admin/emotional-logs",
  //     },
  //     {
  //       text: "Emotion Types",
  //       link: "/admin/emotion-types",
  //     },
  //   ],
  // },
  // {
  //   icon: "briefcase",
  //   text: "Employment Management",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Employments",
  //       link: "/admin/employments",
  //     },
  //     {
  //       text: "Pro Schedules",
  //       link: "/admin/pro-schedules",
  //     },
  //   ],
  // },
];

export const appPortalMenu = [
  {
    icon: "dashboard",
    text: "Dashboard",
    link: "/",
    active: false,
  },
  {
    icon: "dashboard",
    text: "Accounts",
    active: false,
    subMenu: [
      {
        text: "Users",
        link: "/users",
      },
      {
        text: "Professionals",
        link: "/professionals",
      }
    ],
  },
  {
    icon: "dashboard",
    text: "Organisations",
    active: false,
    subMenu: [
      {
        text: "Organisations",
        link: "/organisations",
      }
    ],
  },
  {
    icon: "dashboard",
    text: "Employment",
    active: false,
    subMenu: [
      {
        text: "Employment overview",
        link: "/employment-overview",
      },
      {
        text: "Employment details",
        link: "/employment-details",
      },
      {
        text: "Employment support",
        link: "/employment-support",
      }
    ],
  },
  {
    icon: "dashboard",
    text: "Support",
    active: false,
    subMenu: [
      {
        text: "Professional-to-User",
        link: "/professional-to-user",
      },
      {
        text: "Reminders & Notifications",
        link: "/reminders-and-notifications",
      },
      {
        text: "Community Post",
        link: "/community-post",
      }
    ],
  },
  {
    icon: "dashboard",
    text: "Logs",
    active: false,
    subMenu: [
      {
        text: "Check in/out log",
        link: "/check-in-out-log",
      },
      {
        text: "Emotional log",
        link: "/emotional-log",
      },
      {
        text: "Location log",
        link: "/location-log",
      },
      {
        text: "Goal Tracking",
        link: "/goal-tracking",
      },
    ],
  },
  {
    icon: "dashboard",
    text: "Config",
    active: false,
    subMenu: [
      {
        text: "Location tracking",
        link: "/location-tracking",
      },
      {
        text: "Goals",
        link: "/goals",
      },
    ],
  },
  {
    icon: "dashboard",
    text: "Subscriptions",
    active: false,
    subMenu: [
      {
        text: "Subscriptions",
        link: "/subscriptions",
      },
      {
        text: "Accounts subscriptions",
        link: "/accounts-subscriptions",
      }
    ],
  },
  // {
  //   icon: "dashboard",
  //   text: "Chatbot",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Conversations",
  //       link: "/chatbot/conversations",
  //     },
  //     {
  //       text: "Components",
  //       link: "/chatbot/components",
  //     },
  //     // {
  //     //   text: "Try the bot",
  //     //   link: "/admin/chatbot/test",
  //     // },
  //     // {
  //     //   text: "Train",
  //     //   link: "/admin/chatbot/train",
  //     // },
  //   ],
  // }
  // {
  //   icon: "task-c",
  //   text: "Goal Management",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Goals",
  //       link: "/goals",
  //     },
  //     {
  //       text: "Goal Frequency",
  //       link: "/goal-frequency",
  //     }
  //   ],
  // },
  // {
  //   icon: "help-alt",
  //   text: "Support",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Check in/out log",
  //       link: "/attendance",
  //     },
  //     // {
  //     //   text: "Support Metrics",
  //     //   subMenu: [
  //     //     {
  //     //       text: "Soft skills",
  //     //       link: "/support-metrics/soft-skills",
  //     //     },
  //     //     {
  //     //       text: "Hard skills",
  //     //       link: "/support-metrics/hard-skills",
  //     //     },
  //     //     {
  //     //       text: "Motor skills",
  //     //       link: "/support-metrics/motor-skills",
  //     //     },
  //     //     {
  //     //       text: "Others",
  //     //       link: "/support-metrics/others",
  //     //     },
  //     //   ],
  //     // },
  //     // {
  //     //   text: "Support Logs",
  //     //   link: "/support-logs",
  //     // },
  //     {
  //       text: "Emotional log",
  //       link: "/emotional-logs",
  //     },
  //     {
  //       text: "Employment",
  //       link: "/employments",
  //     },
  //     {
  //       text: "Location log",
  //       link: "/location-tracking",
  //     },
  //   ],
  // },
  // {
  //   icon: "happy",
  //   text: "Emotional Management",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Emotional Logs",
  //       link: "/emotional-logs",
  //     },
  //     {
  //       text: "Emotion Types",
  //       link: "/emotion-types",
  //     },
  //   ],
  // },
  // {
  //   icon: "briefcase",
  //   text: "Employment Management",
  //   active: false,
  //   subMenu: [
  //     {
  //       text: "Employments",
  //       link: "/employments",
  //     },
  //     {
  //       text: "Pro Schedules",
  //       link: "/pro-schedules",
  //     },
  //   ],
  // },
];
