import { useDispatch, useSelector } from "react-redux";
import { useState, useLayoutEffect, useEffect } from "react";
import ReactDataTable from "../../../components/table/ReactDataTable";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  Button,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
  Row,
  Col,
  ModalComponent,
  TextInput,
  TextareaInput,
  SelectField,
  PhoneNumberWithCountryCodeInput,
} from "../../../components/Components";
import {
  onChangePageState,
  onFormCancel,
  toggleModal,
} from "../../../redux/features/userSlice";
import {
  useCreateUserMutation,
  useGetUsersQuery,
} from "../../../redux/api/users/usersApi";
import { userColumns } from "./DataColumns";
import {
  Breadcrumb,
  BreadcrumbItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { useLazyGetOrganisationsQuery } from "../../../redux/api/organisations/organisationApi";
import { useLazyGetRolesQuery } from "../../../redux/api/authorization-management/authorizationApi";
import { toast } from "react-toastify";
import { useGetPartnerAccountsQuery } from "../../../redux/api/accounts/partnerApi";
import { useCreatePartnerAccountsMutation, useDeletePartnerAccountMutation, useUpdatePartnerAccountMutation } from "../../../redux/api/partners/partnerAccountApi";

const PartnerAccounts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state) => state.users);
  const defaultValues = {
    mobile_country_calling_code: "SG",
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    control,
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: defaultValues,
  });
  const { data, isFetching } = useGetPartnerAccountsQuery({
    offset: state.offset,
    limit: state.itemsPerPage,
    total: true,
    reverse: true,
  });
  const [
    createPartnerAccounts,
    {
      isLoading: isCreateLoading,
      isError: isCreateError,
      isSuccess: isCreateSuccess,
      error: createError,
    },
  ] = useCreatePartnerAccountsMutation();
  const[
    updatePartnerAccount,
    {
      isLoading: isUpdateLoading,
      isError: isUpdateError,
      isSuccess: isUpdateSuccess,
      error: updateError,
    },
  ] = useUpdatePartnerAccountMutation();
  const[
    deletePartnerAccount,
    {
      isLoading: isDeleteLoading,
      isError: isDeleteError,
      isSuccess: isDeleteSuccess,
      error: deleteError,
    },
  ] = useDeletePartnerAccountMutation();
  const orgField = watch("organisation");
  const [
    getOrganisations,
    {
      data: orgData,
      isSuccess: isOrgSuccess,
      isFetching: isOrgFetching,
      isError: isOrgError,
    },
  ] = useLazyGetOrganisationsQuery({
    offset: 0,
    limit: 0,
  });
  const [
    getRoles,
    {
      data: roleData,
      isSuccess: isRlSuccess,
      isFetching: isRlFetching,
      isError: isRlError,
    },
  ] = useLazyGetRolesQuery({
    offset: 0,
    limit: 0,
    q: {
      organisation: orgField?.value,
    },
  });
  const [tableData, setTableData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  const changePageState = ({ offset = 0, itemsPerPage }) => {
    dispatch(onChangePageState({ offset, itemsPerPage: itemsPerPage }));
  };

  useLayoutEffect(() => {
    if (data) {
      setTableData(data.accounts);
      setTotalItems(data.total);
    }
  }, [data]);

  const onAddBtnClicked = (type) => {
    dispatch(toggleModal({ type: "add" }));
    getOrganisations(undefined, true);
    getRoles(undefined, true);
  };

  const onFormSubmit = async (data) => {
    if (data.organisation) {
      data.organisation_id = data.organisation.value;
      data.organisation_name = data.organisation.label;
    }
    if (state.modal.edit) {
      updatePartnerAccount({
        data: data,
      });
    }
    if (state.modal.add) {
      createPartnerAccounts(data);
    }
  };

  useEffect(() => {
    if (isCreateSuccess) {
      dispatch(toggleModal({ type: "add" }));
      reset();
    }
    if (isCreateError && createError) {
      toast.error(createError?.data?.message);
    }
  }, [isCreateSuccess, isCreateError]);

  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(toggleModal({ type: "edit" }));
      reset();
      toast.success("Edited successfully");
    }
    if (isUpdateError && updateError) {
      toast.error(updateError?.data?.message);
    }
  }, [isUpdateSuccess, isUpdateError]);

  useEffect(() => {
    if (isDeleteSuccess) {
      toast.success("Deleted successfully");
    }
    if (isDeleteError && deleteError) {
      toast.error(deleteError?.data?.message);
    }
  }, [isDeleteSuccess, isDeleteError]);

  useLayoutEffect(() => {
    if (orgField) {
      getRoles(
        {
          offset: 0,
          limit: 0,
          q: JSON.stringify({ organisation: orgField.value }),
        },
        true
      );
      if (isRlSuccess || isRlError) {
        setValue("role", "");
      }
    }
  }, [orgField]);

  const onRowClicked = (row, e) => {
    e.stopPropagation();
    const action = e.target.getAttribute("data-action");
    switch (action) {
      case "details":
        // navigate(`../${row.id}`);
        break;
      case "edit":
        dispatch(toggleModal({ type: "edit", item: row }));
        reset({
          ...row,
          password: "",
        });
        break;
      case "remove":
        //dispatch delete
        deletePartnerAccount({
          email: row.email,
        })
        break;
      default:
    }
  };

  return (
    <>
      <Head title="Users"></Head>
      <Content>
        <Block>
          <BlockHead>
            <Breadcrumb
              tag="nav"
              listTag="div"
              className="breadcrumb-arrow fs-18px"
            >
              <BreadcrumbItem tag="a" href="../">
                Home
              </BreadcrumbItem>
              <BreadcrumbItem active>Partner Accounts</BreadcrumbItem>
            </Breadcrumb>
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>Partner Accounts</BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent>
                <div className="toggle-wrap nk-block-tools-toggle">
                  <Button
                    className="toggle d-none d-md-inline-flex mr-3"
                    color="primary"
                    onClick={() => {
                      onAddBtnClicked();
                    }}
                  >
                    <Icon name="plus"></Icon>
                    <span>Create Account</span>
                  </Button>
                </div>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <PreviewCard>
            <div className="table-wrapper is-scrollable">
              <ReactDataTable
                data={tableData}
                columns={userColumns}
                offset={state.offset}
                itemsPerPage={state.itemsPerPage}
                pagination
                totalItems={totalItems}
                progressPending={isFetching}
                className="nk-tb-list"
                selectableRows
                changePageState={changePageState}
                onRowClicked={onRowClicked}
              />
            </div>
          </PreviewCard>
        </Block>

        <ModalComponent
          isOpen={state.modal.add || state.modal.edit}
          backdrop={"static"}
          title={`${state.modal.add ? "Add" : "Edit"} Account`}
          onModalCancel={() => {
            dispatch(onFormCancel());
            reset(defaultValues);
          }}
        >
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Row className="g-3">
              <TextInput
                name={"email"}
                label={"Email"}
                required
                register={{
                  ...register("email", {
                    required: "This field is required",
                  }),
                }}
                errors={errors}
                md="12"
              />
              <TextInput
                name={"name"}
                label={"Name"}
                required
                register={{
                  ...register("name", {
                    required: "This field is required",
                  }),
                }}
                errors={errors}
                md="12"
              />
              <TextInput
                name={"password"}
                label={"Password"}
                required={!state.modal.edit}
                register={{
                  ...register("password", {
                    required: !state.modal.edit,
                  }),
                }}
                errors={errors}
                md="12"
              />
              {!state.modal.edit && (<SelectField
                name={"organisation"}
                label={"Organisation"}
                control={control}
                rules={{ required: "This field is required" }}
                options={
                  orgData?.organisations.map((item) => ({
                    label: item.name,
                    value: item.id,
                  })) ?? {}
                }
                isLoading={isOrgFetching}
                isDisabled={isOrgFetching}
                errors={errors}
                md="12"
              />)}
              <PhoneNumberWithCountryCodeInput
                label={"Mobile number"}
                nameCode={"mobile_country_calling_code"}
                nameInputField={"mobile_number"}
                register={register}
                control={control}
                errors={errors}
                md="12"
              />

              <Col size="12">
                <Button
                  color="primary"
                  type="submit"
                  disabled={isCreateLoading || isUpdateLoading}
                >
                  {(isCreateLoading || isUpdateLoading) && <Spinner type="grow" size="sm" />}
                  <span>{state.modal.add ? "Add" : "Edit"}</span>
                </Button>
              </Col>
            </Row>
          </form>
        </ModalComponent>

        {/* <ModalComponent
          isOpen={state.modal.quick_views}
          toggle={() => dispatch(toggleModal({ type: "quick_views" }))}
          title={"Quote Details"}
          onModalCancel={() => {
            dispatch(onFormCancel());
          }}
        >
          {state.modal.item && (
            <Row className="gy-3">
              <Col lg={6}>
                <span className="sub-text">Quote Id</span>
                <span className="caption-text">{state.modal.item.id}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Quote</span>
                <span className="caption-text">{state.modal.item.quote}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Author</span>
                <span className="caption-text">{state.modal.item.author}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Created Time</span>
                <span className="caption-text">
                  {state.modal.item.created_time}
                </span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Last Updated Time</span>
                <span className="caption-text">
                  {state.modal.item.last_updated_time}
                </span>
              </Col>
            </Row>
          )}
        </ModalComponent> */}
      </Content>
    </>
  );
};


export default PartnerAccounts;
