import { useDispatch, useSelector } from "react-redux";
import { useState, useLayoutEffect } from "react";
import {
  Button,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
  ReactDataTable,
  DatePickerInput,
  Row,
  Col,
  SelectField,
  TextInput,
  ModalComponent,
} from "../../../components/Components";
import { subcriptionsColumns } from "./DataColumns";
import {
  onChangePageState,
  toggleModal,
  onFormCancel,
} from "../../../redux/features/app-portal/subcriptions/subcriptionSlice";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { useForm } from "react-hook-form";
import { Spinner } from "reactstrap";
import { useGetPartnerSubscriptionsQuery } from "../../../redux/api/subscription/subscriptionApi";

const Subcriptions = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.subcription);
  const { data, isFetching, isSuccess } = useGetPartnerSubscriptionsQuery({
    offset: state.offset,
    limit: state.itemsPerPage,
    total: true,
    reverse: true,
  });
  const [tableData, setTableData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  const changePageState = ({ offset = 0, itemsPerPage }) => {
    dispatch(onChangePageState({ offset, itemsPerPage: itemsPerPage }));
  };

  useLayoutEffect(() => {
    if (data) {
      setTableData(data.subscriptions);
      setTotalItems(data.total);
    }
  }, [data]);

  const onAddBtnClicked = (e) => {
    dispatch(toggleModal({ type: "add" }));
  };

  return (
    <>
      <Head title="Subcriptions"></Head>
      <Content>
        <Block>
          <BlockHead>
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>Subcriptions</BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <PreviewCard>
            <div className="table-wrapper is-scrollable">
              <ReactDataTable
                data={tableData}
                columns={subcriptionsColumns}
                offset={state.offset}
                itemsPerPage={state.itemsPerPage}
                pagination
                totalItems={totalItems}
                progressPending={isFetching}
                className="nk-tb-list"
                selectableRows
                changePageState={changePageState}
              />
            </div>
          </PreviewCard>
        </Block>
      </Content>
    </>
  );
};

export default Subcriptions;
