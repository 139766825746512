import { useDispatch, useSelector } from "react-redux";
import { useState, useLayoutEffect, useEffect } from "react";
import ReactDataTable from "../../../components/table/ReactDataTable";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  Button,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
  Row,
  Col,
  ModalComponent,
  SelectField,
} from "../../../components/Components";
import {
  onChangePageState,
  toggleModal,
  onFormCancel,
} from "../../../redux/features/admin-portal/subscriptions/partnerAccountSubscriptionSlice";
import { useNavigate } from "react-router-dom";
import { useGetPartnerAccountSubscriptionsQuery } from "../../../redux/api/subscription/subscriptionApi";
import { partnerAccountSubscriptionColumns } from "./DataColumns";

const PartnerAccountSubscriptions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state) => state.partnerAccountSubscription);
  const {
    data: partnerSubscriptionsData,
    isLoading: isGetPartnerSubscriptionsLoading,
  } = useGetPartnerAccountSubscriptionsQuery({
    offset: state.offset,
    items_per_page: state.itemsPerPage,
    total: true,
    reverse: true,
  });
  const [tableData, setTableData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    if (partnerSubscriptionsData) {
      setTableData(partnerSubscriptionsData.subscriptions);
      setTotalItems(partnerSubscriptionsData.total);
    }
  }, [partnerSubscriptionsData]);

  const changePageState = ({ offset = 0, itemsPerPage }) => {
    dispatch(onChangePageState({ offset, itemsPerPage: itemsPerPage }));
  };

  const onRowClicked = (row, e) => {
    e.stopPropagation();
    const action = e.target.getAttribute("data-action");
    switch (action) {
      case "details":
        navigate(`../${row.id}`);
        break;
      case "remove":
        //dispatch delete
        break;
      case "quick_views":
        dispatch(toggleModal({ type: "quick_views", item: row }));
        break;
      default:
    }
  };

  return (
    <>
      <Head title="Partner Account Subscriptions"></Head>
      <Content>
        <Block>
          <BlockHead>
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>Partner Account Subscriptions</BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <PreviewCard>
            <div className="table-wrapper is-scrollable">
              <ReactDataTable
                data={tableData}
                columns={partnerAccountSubscriptionColumns}
                offset={state.offset}
                itemsPerPage={state.itemsPerPage}
                pagination
                totalItems={totalItems}
                className="nk-tb-list"
                selectableRows
                changePageState={changePageState}
                onRowClicked={onRowClicked}
              />
            </div>
          </PreviewCard>
        </Block>
      </Content>
    </>
  );
};

export default PartnerAccountSubscriptions;
